<template>
    <v-container>
      <!-- <NavBar :step="7" /> -->
  
      <Titulo></Titulo>
  
      <br />
      <br />
  
      <div class="text-center">
        <h4
          style="
            font-family: Helvetica;
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            letter-spacing: -0.01em;
            text-transform: uppercase;
            color: #4F74E3;
            text-transform: uppercase;
          "
        >
          ¡Todo listo!
          <P
            style="
              font-family: Helvetica;
              font-style: normal;
              font-weight: 700;
              font-size: 20px;
              line-height: 25px;
              letter-spacing: -0.01em;
              text-transform: uppercase;
              color: #2d387c;
              text-transform: uppercase;
            "
          >
            Ahora estás asegurado
          </P>
        </h4>
      </div>
  
      <v-row justify="center">
        <v-col cols="12" sm="10" md="8" lg="6" xl="4">
          <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto"
            height="250"
            style="margin-top: -2%"
          >
            <v-card-text class="font-weight-medium mt-12 text-center text-subtitle-1 color:primary">
              <label
                class="fontStyle"
                style="
                  font-family: Helvetica;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 20px;
                  color: #232323;
                  opacity: 0.75;
                "
              >
                Recibirás un email con tu póliza para que así la puedas guardar,
                imprimir o consultar cuando necesites.
              </label>
              <p></p>
              <label
                class="fontStyle"
                style="
                  font-family: Helvetica;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 20px;
                  color: #232323;
                  opacity: 0.75;
                "
              >
                La enviaremos a {{ person.email }}. Revisa tu bandeja de entrada,
                carpeta de SPAM y PROMOCIONES por si acaso.
              </label>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
  
      <br />
      <br />
  
      <v-row justify="center">
        <v-col cols="12" sm="10" md="8" lg="6" xl="4">
          <div class="text-center">
            <label
              style="
                font-family: Helvetica;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 15px;
                color: #5b5b5b;
                margin-right: 35px;
              "
            >
              ¿Quieres ver otros seguros?
            </label>
            <br />
            <v-btn
              class="fontStyle"
              style="
                padding: 12px 15px;
                gap: 10px;
                width: 100%;
                max-width: 345px;
                height: 50px;
                background: #2d387c;
                box-shadow: 4px 8px 24px rgba(51, 51, 204, 0.12);
                border-radius: 12px;
                text-transform: inherit;
                color: white;
                text-transform: uppercase;
              "
              elevation="0"
              @click="returnPage()"
            >
              <v-icon color="white" dark left>mdi-arrow-left</v-icon>
              Volver al home
            </v-btn>
          </div>
        </v-col>
      </v-row>
  
      <br />
      <br />
  
      <v-row justify="center">
        <v-col cols="12" sm="10" md="8" lg="6" xl="4">
          <div class="text-center">
            <label
              style="
                font-family: Helvetica;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 15px;
                color: #5b5b5b;
                margin-right: 35px;
              "
            >
              ¿Tienes dudas?
            </label>
            <br />
            <v-btn
              class="fontStyle"
              style="
                padding: 12px 15px;
                gap: 10px;
                width: 100%;
                max-width: 345px;
                height: 50px;
                background: #4F74E3;
                box-shadow: 4px 8px 24px rgba(51, 51, 204, 0.12);
                border-radius: 12px;
                text-transform: inherit;
                color: white;
                text-transform: uppercase;
              "
              elevation="0"
              @click="returnPage()"
            >
              <v-icon color="white" dark left>mdi-arrow-left</v-icon>
              Volver al home
            </v-btn>
          </div>
        </v-col>
      </v-row>
  
      <br />
    </v-container>
  </template>
  
  <script>
  import ShieldService from "../services/shield.service";
  import swal from "sweetalert";
  import Titulo from "../components/Titulo.vue";
  // import NavBar from "@/components/NavBar.vue";
  // import axios from 'axios'
  
  export default {
    components: { Titulo },
    data() {
      return {
        success: null,
        id: null,
        insurance: null,
        load: false,
        person: {
          name: null,
          email: null,
        },
        screen: window.screen.width,
        contactID: null,
        planData: null,
        userData: {
          couponId: null,
          salud: null,
          typePlan: null,
        },
        edadMayor: null,
      };
    },
    methods: {
      returnPage() {
        window.location.href = process.env.VUE_APP_REDIRECTION;
      },
    },
    async mounted() {
      //Obtener Parametro migrates
      //crear vif velse
      const query = this.$route.query;
      if (query) {
        if (query.success === "true") {
          this.userData.typePlan = localStorage.getItem("typePlan");
          this.userData.salud = localStorage.getItem("salud");
          this.userData.couponId =
            localStorage.getItem("couponId") == "null"
              ? null
              : localStorage.getItem("couponId");
          this.edadMayor = localStorage.getItem("edadMayor");
  
          this.planData = await ShieldService.getPlans(
            this.userData.salud,
            this.userData.typePlan,
            this.edadMayor,
            this.userData.couponId
          );
  
          // const personId = localStorage.getItem('personIdWaitingForToku')
          this.success = true;
          // this.id = query.id ?? personId
          // this.person = await ShieldService.getPeople(this.id);
  
          this.person.name = localStorage.getItem("nameFinishMigracion");
          this.person.email = localStorage.getItem("emailFinishMigracion");
  
          this.contactID = localStorage.getItem("contactID");
  
          if (this.contactID) {
            const shieldRes = await ShieldService.updateContactActiveCampain(
              null,
              this.planData[0],
              this.contactID,
              3
            );
            console.log(shieldRes.data);
          }
  
          swal({
            title: "¡Has contratado tu seguro con éxito! ",
            text: "Revisa tu correo electrónico para obtener toda la información.",
            icon: "success",
            button: "Revisar detalle",
          });
  
          this.load = true;
        } else if (query.success === "false") {
          this.success = false;
        }
      }
    },
    // async mounted() {
    //     const query = this.$route.query;
    //     if (query) {
    //         if (query.success === "true") {
    //             this.success = true;
    //             this.id = query.id
  
    //             swal({
    //                 title: "¡Has contratado tu seguro con éxito! ",
    //                 text: "Revisa tu correo electrónico para obtener toda la información.",
    //                 icon: "success",
    //                 button: "Revisar detalle",
    //             });
  
    //             this.insurance = insurance.data.data
  
    //             this.person = await ShieldService.getPeople(this.insurance.person
    //             );
  
    //             this.load = true
  
    //         } else if (query.success === "false") {
    //             this.success = false;
    //         }
    //     }
    // },
  };
  </script>
  
  <style>
  .v-alert {
    position: fixed;
    left: 50%;
    bottom: 50px;
    transform: translate(-50%, 100%);
    margin: 0 auto;
  }
  
  .fontStyle{
  
  
  font-family: 'Inter';
  font-style: normal;
  
  color: #333333;
  }
  
  html
    body
    div#app.v-application.v-application--is-ltr.theme--light
    div.v-application--wrap
    main.v-main
    div.v-main__wrap
    div.container
    div.row
    div.text-center.col
    div.mx-auto.v-card.v-sheet.theme--light.elevation-2
    div.text-center.col {
    margin: auto;
  }
  </style>
  